import React from 'react'
import { StaticQuery, Link, graphql } from 'gatsby'

import Logo from '../components/SVG/Logo'
import ToituCarbonzero from '../components/SVG/ToituCarbonzero'
import ToituEnviromark from '../components/SVG/ToituEnviromark'
import GreenStar from '../components/SVG/GreenStar'

const FooterMenu = ({ data }) => {
  return (
    <ul className="list-reset">
      {data.map((item, i) => {
        const slug = item.node.data.link.uid
        let link = `/${slug === 'home' ? '' : slug}`

        return (
          <li key={i}>
            {i === 7 ? <span className="block">&mdash;</span> : ''}
            <Link to={link} className="link">
              {item.node.data.title}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

const FooterAddress = ({ link, address }) => {
  return (
    <a
      className="link inline-block"
      rel="noopener noreferrer"
      target="_blank"
      href={link.url}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: address.html,
        }}
      />
      New Zealand
    </a>
  )
}

const FooterContact = ({ data }) => {
  return (
    <ul className="list-reset">
      {data.email_address && (
        <li>
          <a
            className="link inline-block"
            href={`mailto:${data.email_address}`}
          >
            Email us
          </a>
        </li>
      )}
      {data.phone_number && (
        <li>
          <a className="link inline-block" href={`tel:${data.phone_number}`}>
            {data.phone_number}
          </a>
        </li>
      )}
      <li>
        <a
          className="link inline-block"
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.facebook.com/fortehealth/"
        >
          Facebook
        </a>
      </li>
    </ul>
  )
}

const FooterLogos = () => {
  return (
    <div className="sm:flex sm:-mx-4">
      <div className="sm:px-4 mb-4 sm:mb-0">
        <div className="w-12">
          <ToituCarbonzero />
        </div>
      </div>
      <div className="sm:px-4 mb-4 sm:mb-0">
        <div className="w-12">
          <ToituEnviromark />
        </div>
      </div>
      <div className="sm:px-4">
        <div className="w-32">
          <GreenStar />
        </div>
      </div>
    </div>
  )
}

const Footer = ({ siteTitle, location }) => (
  <StaticQuery
    query={graphql`
      query {
        allPrismicMenuLink(
          sort: { fields: [data___order_footer], order: ASC }
        ) {
          edges {
            node {
              data {
                link {
                  uid
                }
                title
                order_header
                order_footer
              }
            }
          }
        }
        prismicSettings {
          data {
            phone_number
            fax_number
            email_address
            physical_address_title
            physical_address {
              html
            }
          }
        }
        prismicDirections {
          data {
            map_link {
              url
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <div className="pb-14 px-3 lg:px-14 border-b-7 border-green">
          <div className="border-t border-grey-light pt-12 w-full lg:hidden" />
          <div className="lg:flex lg:-mx-2">
            <div className="lg:w-1/3 xl:w-1/2 lg:px-2">
              <Link to="/">
                <div className="logo logo--footer mb-16 lg:mb-0">
                  <Logo />
                </div>
              </Link>
            </div>
            <div className="lg:w-2/3 xl:w-1/2 lg:px-2 leading-loose text-sm tracking-sm">
              <div className="lg:flex lg:-mx-2">
                <div className="lg:w-1/3 lg:px-2 mb-16 lg:mb-0">
                  <FooterMenu data={data.allPrismicMenuLink.edges} />
                </div>
                <div className="lg:w-2/3 lg:px-2">
                  <div className="md:flex md:-mx-2 lg:mb-8">
                    <div className="md:w-1/2 md:px-2 mb-16 lg:mb-0">
                      <FooterAddress
                        link={data.prismicDirections.data.map_link}
                        address={data.prismicSettings.data.physical_address}
                      />
                    </div>
                    <div className="md:w-1/2 md:px-2 mb-16 lg:mb-0">
                      <FooterContact data={data.prismicSettings.data} />
                    </div>
                  </div>
                  <div className="mb-16 lg:mb-8">
                    <FooterLogos />
                  </div>
                  <p className="text-xs text-grey opacity-50 tracking-sm">
                    &copy; {siteTitle} {new Date().getFullYear()}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }}
  />
)

export default Footer
